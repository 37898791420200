<template>
  <div>
    <b-button
      size="sm"
      variant="secondary"
      class="my-2 btn-action "
      style="border-style: none;"
      @click="hasHistory() ? $router.go(-1) : $router.push('/')"
    >
      <feather-icon icon="ArrowLeftIcon" size="25" />
    </b-button>
    <b-card :title="product.Articulo">
      <b-row>
        <b-col cols="12" lg="6">
          <p>
            <strong>{{ $i18n.messages.CodArt }}:</strong> {{ product.CodArt }}
          </p>
        </b-col>
        <b-col cols="12" lg="6">
          <p>
            <strong>{{ $i18n.messages.ArticuloPadre }}:</strong>
            {{ product.ArticuloPadre }}
          </p>
        </b-col>
        <b-col cols="12" lg="6">
          <p>
            <strong>{{ $i18n.messages.Familia }}:</strong> {{ product.Familia }}
          </p>
        </b-col>
        <b-col cols="12" lg="6">
          <p>
            <strong>{{ $i18n.messages.Formato }}:</strong> {{ product.Formato }}
          </p>
        </b-col>
        <b-col cols="12" lg="6" v-if="user.rol_id === 0 || user.rol_id === 1">
          <p>
            <strong>{{ $i18n.messages.Stock }}:</strong>
            {{
              Number(product.Stock).toLocaleString("de-DE", {
                maximumFractionDigits: 4,
              })
            }}
          </p>
        </b-col>
        <b-col cols="12" lg="6">
          <p>
            <strong>{{ $i18n.messages.DisponibleM2 }}:</strong>
            {{
              Number(product.Disponible).toLocaleString("de-DE", {
                maximumFractionDigits: 4,
              })
            }}
          </p>
        </b-col>
        <b-col cols="12" lg="6">
          <p>
            <strong>{{ $i18n.messages.lastProd }}:</strong>
            {{
              product.FechaProg
                ? product.FechaProg.split("/")[1] +
                  "/" +
                  product.FechaProg.split("/")[0] +
                  "/" +
                  product.FechaProg.split("/")[2]
                : $i18n.messages.noDate
            }}
          </p>
        </b-col>
        <b-col
          col
          lg="8"
          offset-lg="2"
          class="mt-3"
          v-if="hasLotes && (user.rol_id === 0 || user.rol_id === 1)"
        >
          <b-table
            responsive="sm"
            :items="lotesVisibles"
            :fields="fieldsStock"
          />
        </b-col>
        <b-col
          col
          lg="8"
          offset-lg="2"
          class="mt-3"
          v-if="user.rol_id === 2 || user.rol_id === 3"
        >
          <b-table
            responsive="sm"
            :items="lotesVisibles"
            :fields="fieldsNoStock"
          />
        </b-col>
        <b-col col lg="8" offset-lg="2" class="text-center mt-3">
          <b-button variation="primary" @click="showModal">
            {{ $i18n.messages.Anyadir }}
          </b-button>
        </b-col>
      </b-row>
      <modal-add-product
        :product="productAdd"
        v-if="productAdd && Object.keys(productAdd).length"
      />
    </b-card>
  </div>
</template>

<script>
import { BCard, BRow, BCol, BTable, BButton } from "bootstrap-vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import ModalAddProduct from "@/views/ModalAddProduct.vue";
export default {
  components: {
    BCard,
    BCol,
    BRow,
    BTable,
    BButton,
    ModalAddProduct,
  },
  data() {
    return {
      modal: true,
      lotesVisibles: [],
      fieldsStock: [
        {
          key: "Lote",
          label: this.$i18n.messages.Lote,
        },
        {
          key: "Palet",
          label: this.$i18n.messages.Pale,
          formatter: (value) => {
            if (value === "E") {
              return "Europallet";
            } else if (value === "N") {
              return "Normal";
            } else {
              return value;
            }
          },
        },
        {
          key: "Stock",
          label: this.$i18n.messages.Stock,
          formatter: (value) => {
            return value
              ? Number(value[1]).toLocaleString("de-DE", {
                  maximumFractionDigits: 4,
                })
              : 0;
          },
        },
        {
          key: "Disponible",
          label: this.$i18n.messages.Disponible,
          formatter: (value) => {
            return value
              ? Number(value[1]).toLocaleString("de-DE", {
                  maximumFractionDigits: 4,
                })
              : 0;
          },
        },
      ],
      fieldsNoStock: [
        {
          key: "Lote",
          label: this.$i18n.messages.Lote,
        },
        {
          key: "Palet",
          label: this.$i18n.messages.Pale,
          formatter: (value) => {
            if (value === "E") {
              return "Europallet";
            } else if (value === "N") {
              return "Normal";
            } else {
              return value;
            }
          },
        },
        {
          key: "Disponible",
          label: this.$i18n.messages.Disponible,
          formatter: (value) => {
            return value
              ? Number(value[1]).toLocaleString("de-DE", {
                  maximumFractionDigits: 4,
                })
              : 0;
          },
        },
      ],
    };
  },
  created() {
    this.setLoading(true);

    console.log("this.$route.params.id: ", this.$route.params.id);

    this.getProduct(this.$route.params.id).then(() => {
      // this.setLoading(false);
    });
    this.getLotes(this.$route.params.id).then(() => {
      this.setLoading(false);
      console.log(this.lotes);
      this.lotesVisibles = this.lotes;

      var i = this.lotesVisibles.length;
      while (i--) {
        if (this.lotesVisibles[i].Disponible[1] === 0) {
          this.lotesVisibles.splice(i, 1);
        }
      }
    });
  },
  computed: {
    ...mapGetters({
      product: "products/getProduct",
      productAdd: "products/getProductAdd",
      lotes: "products/getLotes",
      user: "auth/getUser",
      currentCustomer: "customers/getCurrentCustomer",
    }),
    hasLotes() {
      return this.lotes.length;
    },
  },
  methods: {
    hasHistory() {
      return window.history.length > 2;
    },
    showModal() {
      if (this.currentCustomer === "" || this.currentCustomer === null) {
        this.recoverCurrentCustomer();
        /*  this.modalShow = true; */
      } else {
        this.setShowModalAdd(true);
      }
    },
    ...mapActions({
      getProduct: "products/getProduct",
      getLotes: "products/getLotes",
    }),
    ...mapMutations({
      setShowModalAdd: "products/setShowModalAdd",
      setLoading: "app/setLoading",
      recoverCurrentCustomer: "customers/recoverCurrentCustomer",
    }),
  },
};
</script>
